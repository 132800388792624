.home {
  /* the combo of setting below let the main content ara stay a certain max with while allowing the top header to stay as wide as screen */
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

.home__row {
  display: flex;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.home__image {
  width: 100%;
  z-index: -1;
  /* elements can creep in from bottom with negative margin bottom*/
  margin-bottom: -150px;
  /* gradient fade effect */
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
